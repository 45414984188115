import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FacebookLogin2 from "./facebookLogin/facebook";

export default class FacebookLogin extends Component {
  static defaultProps = {
    appId: "xxxxxxx",
    autoLoad: false,
    fields:'name,email,picture',
    callback: ()=>{},
    onClick: ()=>{},
    containerStyle: { opacity: '0', width: '1px', height: '0px' },
    version: '8.0',
    cookie: true,
    xfbml: true,
    render: (renderProps) => (
      <button
        onClick={renderProps.onClick}
      >Facebook Login
      </button>
    ) ,

  }
  static propTypes = {
    appId: PropTypes.string,
    autoLoad: PropTypes.bool,
    fields:PropTypes.string,
    callback: PropTypes.func,
    containerStyle: PropTypes.object,
    version: PropTypes.string,
    cookie: PropTypes.bool,
    xfbml: PropTypes.bool,
    render: PropTypes.func ,
    onClick:  PropTypes.func ,

  }

  render() {
    return (
      <FacebookLogin2
        appId={this.props.appId}
        autoLoad={this.props.autoLoad}
        fields={this.props.fields}
        callback={this.props.callback}
        className='FacebookLogin'
        version={this.props.version}
        cookie={this.props.cookie}
        xfbml={this.props.xfbml}
        render={this.props.render}
      />
    )
  }
}
